<template>
    <v-container>
        <v-row>
            <v-col cols="3" md="5" lg="3">
                <v-card
                    class="pa-5 profile"
                    height="calc(100vh - 210px)"
                    min-width="250"
                >
                    <v-card-title class="px-0">{{
                        $t('profile:yourData')
                    }}</v-card-title>
                    <div class="profile__content">
                        <div class="profile__avatar">
                            <v-avatar size="160" color="primary">
                                <img
                                    v-if="getProfilePicture.url"
                                    :src="getProfilePicture.url"
                                    alt="avatar"
                                />

                                <span v-else class="white--text headline"
                                    >{{ getProfileDetails.name[0]
                                    }}{{ getProfileDetails.lastname[0] }}</span
                                >
                            </v-avatar>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="profile__edit"
                                        @click="open = true"
                                        width="25"
                                        v-on="on"
                                        height="25"
                                        icon
                                    >
                                        <v-icon size="16" color="white"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>{{ $t('profile:insertPhoto') }}</span>
                            </v-tooltip>
                        </div>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="title text-wrap center"
                                    >{{ getProfileDetails.name }}
                                    {{
                                        getProfileDetails.lastname
                                    }}</v-list-item-title
                                >
                                <v-list-item-subtitle class="center">
                                    {{ getProfileDetails.position }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>

                    <v-text-field
                        readonly
                        :label="$t('profile:mail')"
                        :value="getProfileDetails.mail"
                    ></v-text-field>
                    <v-text-field
                        readonly
                        :label="$t('profile:mobilePhone')"
                        :value="getProfileDetails.phone"
                    ></v-text-field>
                    <v-text-field
                        readonly
                        :label="$t('profile:login')"
                        :value="getProfileDetails.login"
                    ></v-text-field>
                    <v-text-field
                        readonly
                        :label="$t('profile:password')"
                        value="********"
                    >
                        <template slot="append-outer">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        v-on="on"
                                        @click="
                                            $emit('open-profile'),
                                                clearProfileModal()
                                        "
                                        class="cursor-pointer"
                                    >
                                        mdi-lock
                                    </v-icon>
                                </template>
                                <span>{{ $t('global:changePassword') }}</span>
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-card>
            </v-col>
        </v-row>
        <Modal
            :title="$t('global:edit')"
            :open="open"
            width="700"
            v-on:close="closeModal"
        >
            <AEContent :open="open" slot="AEContent" />
            <Buttons @close-modal="open = false" slot="buttons" />
        </Modal>
    </v-container>
</template>
<script>
import AEContent from './Modal/Photo/Photo'
import Buttons from './Modal/Photo/Buttons'
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            open: false,
        }
    },
    components: {
        Buttons,
        AEContent,
    },
    computed: {
        ...mapGetters(['getProfileDetails', 'getProfilePicture']),
    },
    methods: {
        ...mapMutations(['clearProfileModal']),
        
        closeModal() {
            // this.clearFotoModal()
            
            this.open = false
        },
    },
}
</script>
