<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getProfileError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('profile:editProfile') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('profile:changePhoto')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <div class="pa-4 p-relative">
                                           
                                            <Cropper
                                                class="cropper"
                                                @change="changePictures()"
                                                :src="image"
                                                :stencilComponent="
                                                    $options.components
                                                        .CircleStencil
                                                "
                                                ref="cropper"
                                            />
                                            <div class="cropper__content">
                                                <v-icon v-if="!error"
                                                    size="60"
                                                    class="ma-5"
                                                    color="#445D75"
                                                    >mdi-arrow-down-bold-circle</v-icon
                                                >
                                                 <v-icon v-else
                                                    size="60"
                                                    class="ma-5"
                                                    color="red"
                                                    >mdi-alert-circle-outline</v-icon
                                                >
                                                <p v-if="!error">
                                                    {{
                                                        $t(
                                                            'profile:cropperAction'
                                                        )
                                                    }}
                                                </p>
                                                 <p v-else>
                                                    {{
                                                        $t(
                                                            'profile:error'
                                                        )
                                                    }}
                                                </p>
                                            </div>

                                            <div class="button-wrapper">
                                                <form
                                                    enctype="multipart/form-data"
                                                >
                                                    <span
                                                        class="button img-buttons"
                                                    >
                                                        <input
                                                            type="file"
                                                            @change="
                                                                uploadImage(
                                                                    $event
                                                                )
                                                            "
                                                            ref="file"
                                                            accept="image/*"
                                                            name="image-event"
                                                            id="image-event"
                                                        />
                                                    </span>
                                                </form>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
export default {
    props: ['open'],
    i18nOptions: { namespaces: ['profile'] },
    data() {
        return {
            e1: 1,
            editable: true,
            image: null,
            file_before: '',
            error:false
        }
    },
    components: {
        Cropper,
        CircleStencil,
    },
    computed: {
        ...mapGetters(['getProfile', 'getProfileError', 'getPictures']),
    },
    watch: {
        'open': function(){
            if(this.open == false){                
                this.image = null
                document.getElementById("image-event").value = ""
            }
        }
    },
    methods: {
        ...mapMutations(['setPictures']),        
        changePictures() {
            const formData = new FormData()
            const { canvas } = this.$refs.cropper.getResult()
            if (canvas) {
                canvas.toBlob(blob => {
                    formData.append('image', blob)
                    this.setPictures({ formData })
                }, 'image/png')
            }
        },
        uploadImage(event) {
            this.file_before = this.$refs.file.files[0]
            let input = event.target
            // Ograniczenie rozmiaru przyjmowanego zdjęcia do 50mb
            if (input.files && (input.files[0].size / 1024 / 1024) < 50) {
                this.error=false;
                this.$refs.cropper.$el.classList.remove('error_cropper')
                if (input.files[0]) {
                    let reader = new FileReader()
                    reader.onload = e => {
                        this.image = e.target.result
                    }
                    reader.readAsDataURL(input.files[0])
                }
            }else{
                this.$refs.cropper.$el.classList.add('error_cropper')
                this.error=true;
            }
        },
    },
}
</script>
