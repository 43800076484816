var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getProfileError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('profile:editProfile')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('profile:changePassword')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"password__input",attrs:{"append-icon":_vm.show1
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline',"type":_vm.show1 ? 'text' : 'password',"label":_vm.$t('profile:oldPassword'),"counter":"","error-messages":_vm.getProfileError.stepOne.fields
                                                .oldPass},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.getProfile.oldPass),callback:function ($$v) {_vm.$set(_vm.getProfile, "oldPass", $$v)},expression:"getProfile.oldPass"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"password__input",class:_vm.checkLength(_vm.getProfile.password),attrs:{"rules":_vm.rules,"append-icon":_vm.show2
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline',"type":_vm.show2 ? 'text' : 'password',"label":_vm.$t('profile:newPassword'),"counter":"","error-messages":_vm.getProfileError.stepOne.fields
                                                .password,"counter-value":v =>
                                                v.length +
                                                ` ${this.$t(
                                                    'profile:characters'
                                                )}`},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},scopedSlots:_vm._u([{key:"counter",fn:function({ props }){return [_c('v-counter',_vm._b({attrs:{"value":_vm.title.trim().split(' ')
                                                        .length}},'v-counter',props,false))]}}]),model:{value:(_vm.getProfile.password),callback:function ($$v) {_vm.$set(_vm.getProfile, "password", $$v)},expression:"getProfile.password"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"password__input",class:_vm.checkLength(
                                                _vm.getProfile.passwordAgain
                                            ),attrs:{"rules":_vm.rules,"append-icon":_vm.show3
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline',"error-messages":_vm.getProfileError.stepOne.fields
                                                .passwordAgain,"type":_vm.show3 ? 'text' : 'password',"label":_vm.$t('profile:repetPassword'),"counter":"","counter-value":v =>
                                                v.length +
                                                ` ${this.$t(
                                                    'profile:characters'
                                                )}`},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.getProfile.passwordAgain),callback:function ($$v) {_vm.$set(_vm.getProfile, "passwordAgain", $$v)},expression:"getProfile.passwordAgain"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.getProfile.generated))])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('small',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('profile:reminder')))])])],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }