<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <Header
                    :title="$t('global:yourProfile')"
                    :profileUrl="'registries'"
                />

                <Modal
                    :title="$t('global:editPassword')"
                    :open="open"
                    :width="'500'"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        slot="buttons"
                        :action="'create'"
                    />
                </Modal>
                <UserContent @open-profile="openModal" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AEContent from './../../../components/Personal/Profile/Modal/Password/Password'
import Buttons from './../../../components/Personal/Profile/Modal/Password//Buttons'
import UserContent from './../../../components/Personal/Profile/Content'
import Header from './../../../components/Personal/Profile/Header'
import store from './../../../store/index'
import { mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['registries', 'global'] },
    components: {
        Buttons,
        AEContent,
        UserContent,
        Header,
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {},
    methods: {
        ...mapMutations(['clearProfileModal', 'clearProfileErrors']),
        closeModal() {
            this.clearProfileModal()
            this.clearProfileErrors()
            this.open = false
        },
        openModal() {
            this.open = true
        },
    },
}
</script>
