<template>
    <div>
        <v-btn @click="generatePassword" class="buttons buttons--generate">{{
            $t('profile:generatePassword')
        }}</v-btn>
        <v-btn
            @click="edit({ id: getProfileDetails._id })"
            class="buttons buttons--add"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['profile', 'global'] },
    props: {
        action: String,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['updatePassword']),
        ...mapMutations(['generatePassword']),
        async edit(payload) {
            let success = await this.updatePassword(payload)
            if (success) this.$emit('closeModal')
        },
    },
}
</script>
