<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getProfileError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('profile:editProfile') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('profile:changePassword')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye-outline'
                                                    : 'mdi-eye-off-outline'
                                            "
                                            :type="show1 ? 'text' : 'password'"
                                            :label="$t('profile:oldPassword')"
                                            counter
                                            v-model="getProfile.oldPass"
                                            :error-messages="
                                                getProfileError.stepOne.fields
                                                    .oldPass
                                            "
                                            @click:append="show1 = !show1"
                                            class="password__input"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            :rules="rules"
                                            :append-icon="
                                                show2
                                                    ? 'mdi-eye-outline'
                                                    : 'mdi-eye-off-outline'
                                            "
                                            :type="show2 ? 'text' : 'password'"
                                            :label="$t('profile:newPassword')"
                                            counter
                                            :error-messages="
                                                getProfileError.stepOne.fields
                                                    .password
                                            "
                                            :counter-value="
                                                v =>
                                                    v.length +
                                                    ` ${this.$t(
                                                        'profile:characters'
                                                    )}`
                                            "
                                            v-model="getProfile.password"
                                            :class="
                                                checkLength(getProfile.password)
                                            "
                                            @click:append="show2 = !show2"
                                            class="password__input"
                                        >
                                            <template
                                                v-slot:counter="{ props }"
                                            >
                                                <v-counter
                                                    v-bind="props"
                                                    :value="
                                                        title.trim().split(' ')
                                                            .length
                                                    "
                                                ></v-counter> </template
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            :rules="rules"
                                            :append-icon="
                                                show3
                                                    ? 'mdi-eye-outline'
                                                    : 'mdi-eye-off-outline'
                                            "
                                            v-model="getProfile.passwordAgain"
                                            :error-messages="
                                                getProfileError.stepOne.fields
                                                    .passwordAgain
                                            "
                                            :type="show3 ? 'text' : 'password'"
                                            :label="$t('profile:repetPassword')"
                                            counter
                                            :counter-value="
                                                v =>
                                                    v.length +
                                                    ` ${this.$t(
                                                        'profile:characters'
                                                    )}`
                                            "
                                            @click:append="show3 = !show3"
                                            :class="
                                                checkLength(
                                                    getProfile.passwordAgain
                                                )
                                            "
                                            class="password__input"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p>
                                            <span>{{
                                                getProfile.generated
                                            }}</span>
                                        </p></v-col
                                    ></v-row
                                >
                                <v-row>
                                    <v-col cols="12">
                                        <small class="red--text">{{
                                            $t('profile:reminder')
                                        }}</small></v-col
                                    ></v-row
                                >
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['profile'] },
    data() {
        return {
            e1: 1,
            rules: [
                v =>
                    v.length == 0 ||
                    v.length >= 8 ||
                    this.$t('profile:shortPassword'),
            ],
            editable: true,
            show1: false,
            show2: false,
            show3: false,
        }
    },

    computed: {
        ...mapGetters(['getProfile', 'getProfileError']),
    },
    methods: {
        checkLength(v) {
            if (v.length >= 8) return 'success--text'
        },
    },
}
</script>
<style lang="sass">
.success--text>.v-input__control>.v-text-field__details>.v-counter
    color: green !important
</style>
