<template>
    <div>
        <!-- <v-btn @click="select()" class="buttons buttons--generate">{{
            $t('global:select')
        }}</v-btn> -->
        <v-btn
            @click="save()"
            :disabled="disabled"
            class="buttons buttons--add"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['profile', 'global'] },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['sendPictures']),
        async save() {           
            // this.disabled = true
            await this.sendPictures({ id: this.getProfileDetails._id })
            this.$emit('close-modal')
            this.disabled = false
        },
    },
}
</script>
